
    import {Component, Prop, Vue, Emit, Watch} from 'vue-property-decorator';
    // @ts-ignore
    import {getAllHall} from "@/api/public"
    @Component
    export default class SearchHall extends Vue {
      private options: any = {}
      private value: string=''
      @Emit('getHall')
      sendHall(value: number) {}
      private getAll():any{
        getAllHall()
         .then((res:any)=>{
           if(res.code===200){
             this.options=res.data;
           }
         })
      }

      public mounted():void{
        this.getAll();
      }
    }
